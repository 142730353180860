import { useRouter } from 'next/router'
import { useContext, useLayoutEffect } from 'react'

import { useAppSelector } from '@reduxStore/hooks'

import { PagesRoutes } from '@constants_folder/routes'

import { AuthContext } from '@modules/Auth/AuthProvider'

const useMainRedirectUrl = () => {
  const { user } = useContext(AuthContext)
  const router = useRouter()
  const { userProfile } = useAppSelector((state) => state.profile)

  useLayoutEffect(() => {
    if (user && Object.keys(userProfile).length) {
      let redirectUrl = PagesRoutes.forYou

      const userPredefinedRedirect = userProfile?.keeper?.redirect_to?.value

      const predefinedOneXOneTutoring =
        userPredefinedRedirect === '/tutor/individual' ||
        userPredefinedRedirect === '/tutor' // for old users

      if (predefinedOneXOneTutoring) {
        redirectUrl = PagesRoutes.myTutor
      }
      if (userPredefinedRedirect === '/tutor/group') {
        redirectUrl = PagesRoutes.groupLessons
      }

      router.push(redirectUrl)
    }
  }, [user, router, userProfile])
}

export default useMainRedirectUrl
